import { Form, Typography, Input, Button, Alert } from '@pankod/refine-antd'
import { useTranslate, useLogin } from '@pankod/refine-core'
import jwtDecode from 'jwt-decode'
import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { verifyPassword } from 'src/libs/verifyPassword'

const { Title } = Typography

type InitPasswordFormData = {
  password: string
  confirmPassword: string
}

type JWT = {
  login: string
  exp: number
}

export function RecoverPasswordForm() {
  const [query] = useSearchParams()

  if (!query.has('token')) throw new Error('errors.tokenMissing')

  const isNew = query.get('type') === 'new'

  const { token, decodedToken, isExpired } = useMemo(() => {
    const queryToken = query.get('token')!
    const decoded = jwtDecode<JWT>(queryToken)

    return {
      token: queryToken,
      decodedToken: decoded,
      isExpired: checkExpire(decoded.exp),
    }
  }, [query])

  const __ = useTranslate()

  const [form] = Form.useForm()

  const { mutate: login } = useLogin()
  const navigate = useNavigate()

  const httpClient = useHttpClient()
  const { mutate: initPassword, isLoading } = useMutation(
    async (value: InitPasswordFormData) => {
      const { password } = value
      await httpClient.patch('/user/recoverPassword', {
        token,
        password,
      })
      const { login: userLogin } = decodedToken
      login({ password, username: userLogin })
      navigate('/')
    },
  )

  return (
    <Form
      form={form}
      onFinish={initPassword}
      layout="vertical"
      className="form"
    >
      <Title level={4}>
        {__(`pages.recover.title.${isNew ? 'init' : 'recover'}`)}
      </Title>

      {isExpired && (
        <Alert showIcon type="error" message={__('errors.tokenExpired')} />
      )}

      <Form.Item label={__('pages.login.username')}>
        <Typography.Text>{decodedToken.login}</Typography.Text>
      </Form.Item>

      <Form.Item
        label={__('pages.login.password')}
        name="password"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
          {
            async validator(_, value) {
              const [valid, error] = verifyPassword(value)
              if (valid) return Promise.resolve()

              return Promise.reject(new Error(__(error)))
            },
          },
        ]}
        extra={__('pages.login.passwordHelper')}
      >
        <Input.Password disabled={isExpired} />
      </Form.Item>

      <Form.Item
        label={__('pages.login.confirmPassword')}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
          (formInstance) => {
            const { getFieldValue } = formInstance
            return {
              validator(_, value) {
                if (value === getFieldValue('password')) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(__('validation.matchingPassword')),
                )
              },
            }
          },
        ]}
      >
        <Input.Password disabled={isExpired} />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isExpired}
          loading={isLoading}
        >
          {__('buttons.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

function checkExpire(exp: number) {
  return Date.now() >= exp * 1000
}
