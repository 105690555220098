import { Tabs } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

import { LoginForm } from './LoginForm'
import { SignupForm } from './SignupForm'

const { TabPane } = Tabs

export function Form() {
  const translate = useTranslate()

  return (
    <Tabs centered defaultActiveKey="1" className="login-form-tabs">
      <TabPane tab={translate('pages.login.signin')} key="1">
        <LoginForm />
      </TabPane>
      <TabPane tab={translate('pages.login.signup')} key="2">
        <SignupForm />
      </TabPane>
    </Tabs>
  )
}
