import { Input, Form, Button } from '@pankod/refine-antd'
import { useLogin, useNotification, useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useHttpClient } from 'src/adapters/HTTPClient'

export function LoginForm() {
  const __ = useTranslate()
  const { mutate: login } = useLogin()
  const [displayRecover, setDisplayRecover] = useState<boolean>(false)

  if (displayRecover) {
    return <RecoverUserForm onClose={() => setDisplayRecover(false)} />
  }
  return (
    <Form onFinish={login} layout="vertical">
      <Form.Item
        label={__('pages.login.username')}
        name="username"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={__('pages.login.password')}
        name="password"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Button type="link" onClick={() => setDisplayRecover(true)}>
        {__('pages.login.forgotPassword')}
      </Button>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {__('buttons.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

type Props = {
  onClose: () => void
}

type RecoverFormDate = {
  login: string
}

function RecoverUserForm(props: Props) {
  const { onClose } = props
  const __ = useTranslate()

  const httpClient = useHttpClient()
  const { open } = useNotification()

  const { mutate: onSubmit, isLoading } = useMutation(
    (data: RecoverFormDate) => httpClient.patch('user/recover', data),
    {
      onSuccess() {
        open?.({
          type: 'success',
          message: __('notifications.resetPasswordSent'),
        })
      },
      onError(error: { statusCode: number }) {
        const { statusCode } = error
        open?.({
          type: 'error',
          message: __(
            `notifications.${statusCode === 403 ? 'noAccount' : 'error'}`,
            {
              statusCode,
            },
          ),
        })
      },
    },
  )

  return (
    <Form onFinish={onSubmit} layout="vertical">
      <Form.Item
        label={__('pages.login.username')}
        name="login"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <div className="form-actions">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {__('buttons.submit')}
          </Button>
          <Button type="ghost" onClick={onClose}>
            {__('buttons.cancel')}
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}
