import * as Sentry from '@sentry/react'
import { filterConsole } from 'src/libs/filterConsole'

import type { IEnv } from './adapters/Env'
import { config } from './config'

export function setup(env: IEnv) {
  if (env.IS_DEV) {
    console.log('Setting up app with following env config:', env)
  }

  if (!env.IS_DEV) {
    Sentry.init({
      dsn: env.SENTRY_DSN,
      environment: env.APP_ENV,
      release: config.APP_RELEASE,
    })
  }

  // Use this util to remove warnings when they happen inside a dependency sources
  // Use `console.noFilter()` to print all warnings again, and keep original log location
  ;(window.console as any).noFilter = filterConsole([
    'Warning: findDOMNode is deprecated in StrictMode', // from antd <Image /> component
  ])
}
