/* eslint-disable prefer-promise-reject-errors */
import type { AuthProvider as IAuthProvider } from '@pankod/refine-core'
import type { AxiosError, AxiosInstance } from 'axios'
import type { Organization, User } from 'src/types/api'

type Params = {
  nxHttpClient: AxiosInstance
  local: Storage
  translate: (key: string, params: object) => string
}

export enum PermissionEnum {
  'USER',
  'ADMIN',
}

const DOCWALL_KEY = 'docwall-jwt'
const TALLIA_KEY = 'tallia-jwt'

export function AuthProvider(params: Params): IAuthProvider {
  const { nxHttpClient, local, translate } = params

  const t = (key: string, param: object = {}) => translate(key, param)

  async function getUserIdentity() {
    const jwt = local.getItem(DOCWALL_KEY)
    const { data } = await nxHttpClient.get<User>('/me', {
      headers: { jwt },
    })
    return data
  }

  return {
    async login(credentials: { username: string; password: string }) {
      const { username, password } = credentials
      const formData = new FormData()
      formData.append('login', username)
      formData.append('password', password)
      const response = await nxHttpClient.post('/me', formData, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      })
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }

      const jwt = response.data.value
      const user = await nxHttpClient.get<User>('/me', {
        headers: { jwt },
      })
      if (user.data.access_level <= 10 || user.data.access_level >= 100) {
        return Promise.reject({
          message: t('errors.forbiddenAccess'),
          name: t('errors.forbiddenRights'),
        })
      }

      const organization = await nxHttpClient.get<Organization>(
        `/Organization/${user.data.organization_id}`,
        {
          headers: { jwt },
        },
      )

      const { tallia_api_key } = organization.data
      if (!tallia_api_key) {
        return Promise.reject({
          message: t('errors.forbiddenAccess'),
          name: t('errors.forbiddenOrganization'),
        })
      }

      local.setItem(DOCWALL_KEY, jwt)
      local.setItem(TALLIA_KEY, tallia_api_key)

      return Promise.resolve()
    },

    checkError(error: AxiosError) {
      const status = error.response?.status
      if (status === 401 || status === 403) {
        return Promise.reject()
      }
      // other error code (404, 500, etc): no need to log out
      return Promise.resolve()
    },

    checkAuth() {
      if (local.getItem(DOCWALL_KEY) && local.getItem(TALLIA_KEY))
        return Promise.resolve()
      return Promise.reject()
    },

    logout() {
      local.removeItem(DOCWALL_KEY)
      local.removeItem(TALLIA_KEY)
      return Promise.resolve()
    },

    getUserIdentity,

    async getPermissions() {
      const { access_level } = await getUserIdentity()
      if (access_level > 20) {
        return Promise.resolve(PermissionEnum.ADMIN)
      }
      return Promise.resolve(PermissionEnum.USER)
    },
  }
}

export function getDocwallJWT(local: Storage) {
  return local.getItem(DOCWALL_KEY) || undefined
}

export function getTalliaJWT(local: Storage) {
  return local.getItem(TALLIA_KEY) || undefined
}
