import { Skeleton, Typography } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import type { FullCategory } from 'src/types/api'

type Props = {
  id: string
}

export function CategoryField(props: Props) {
  const { id } = props

  const { data, isLoading } = useOne<FullCategory>({
    resource: 'FullCategory',
    id,
  })

  if (isLoading) {
    return <Skeleton paragraph={false} active />
  }
  return <Typography.Text>{data?.data.name}</Typography.Text>
}
