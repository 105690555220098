import { Link } from 'react-router-dom'

export function Title() {
  return (
    <Link to="/">
      <img
        src={`${process.env.PUBLIC_URL}/logo.jpg`}
        alt="tallia logo"
        style={{
          width: '100%',
        }}
      />
    </Link>
  )
}
