import type { DataProvider } from '@pankod/refine-core'

export function antiCorruptionLayerProxy(dataProvider: DataProvider) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const { resource } = params
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }

        return (dataProvider[methodName] as any)(params)
      }
    },
  })
}

type ProxiesMap = Partial<Record<string, Partial<DataProvider>>>

function Proxies(dataProvider: DataProvider): ProxiesMap {
  return {
    documents: documentsProxy(dataProvider),
    user: usersProxy(dataProvider),
  }
}

function documentsProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    getOne(params) {
      return dataProvider.getOne({ ...params, resource: 'document' })
    },
    create(params) {
      return dataProvider.create({
        ...params,
        resource: 'document/new',
      })
    },
  }
}

function usersProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    getList(params) {
      return dataProvider.getList({
        ...params,
        filters: [
          ...params.filters!,
          {
            field: 'deleted',
            operator: 'contains',
            value: ['0', '1'],
          },
        ],
      })
    },
  }
}
