import { Form, Select, useSelect } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import type { FullCategory } from 'src/types/api'

type Props = {
  categoryId?: string
}

export function CategoryInput(props: Props) {
  const { categoryId } = props
  const __ = useTranslate()
  const { selectProps } = useSelect<FullCategory>({
    resource: 'FullCategory',
    optionLabel: 'name',
    defaultValue: categoryId,
  })

  return (
    <Form.Item name="category" label={__('documents.fields.category')}>
      <Select {...selectProps} />
    </Form.Item>
  )
}
