import { Form, Input, Select, Radio } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

export function FormInputs(props: { isSelf?: boolean }) {
  const { isSelf } = props
  const __ = useTranslate()
  const { Option } = Select

  return (
    <>
      <Form.Item label={__('users.fields.email')} name="login" required>
        <Input disabled={isSelf} />
      </Form.Item>
      <Form.Item label={__('users.fields.role')} name="access_level" required>
        <Select disabled={isSelf}>
          <Option value={20}>{__('roles.values.user')} </Option>
          <Option value={50}>{__('roles.values.administrator')}</Option>
        </Select>
      </Form.Item>
      {!isSelf && (
        <Form.Item label={__('users.fields.status')} name="deleted" required>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={[
              { label: 'Actif', value: false },
              { label: 'Inactif', value: true },
            ]}
          />
        </Form.Item>
      )}
      <Form.Item label="organization_id" name="organization_id" hidden />
    </>
  )
}
