// Function to verify password validity
export function verifyPassword(password: String): [boolean, string] {
  if (password.length < 8) return [false, 'validation.passwordLength']
  if (password.match(/[a-z]/g) === null)
    return [false, 'validation.passwordLowerCase']
  if (password.match(/[A-Z]/g) === null)
    return [false, 'validation.passwordUpperCase']
  if (password.match(/[0-9]/g) === null)
    return [false, 'validation.passwordNumber']

  return [true, '']
}
