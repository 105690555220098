import type { Document } from 'src/types/talliaApi'

import { DocumentUpload } from './DocumentUpload'

import { LangsForm } from './LangsForm'

import { MetasForm } from './MetasForm'

import './index.less'

export type DocumentFormProps = {
  document: Document & { id: string }
}

export function DocumentForm(props: DocumentFormProps) {
  const { document } = props

  return (
    <div className="document-form">
      <DocumentUpload documentId={document.id} dropdown />
      <MetasForm document={document} />
      <LangsForm document={document} />
    </div>
  )
}
