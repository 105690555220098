import type { CrudFilters, LogicalFilter } from '@pankod/refine-core'
import removeDiacritics from 'remove-diacritics'

const operatorsMap = {
  // supported filters
  lt: 'lt', // Less than
  gt: 'gt', // Greater than
  lte: 'le', // Less than or equal to
  gte: 'ge', // Greater than or equal to
  null: 'null', // Is null
  nnull: 'nnull', // Is not null
  between: 'btw', // Is between
  in: 'in', // Included in an array
  nin: 'nin', // Not included in an array
  eq: 'eq', // Equal
  ne: 'neq', // Not equal
  contains: 'like', // Contains
  or: 'or',

  // unsupported filters
  containss: null, // Contains, case sensitive
  ncontains: null, // Doesn't contain
  ncontainss: null, // Doesn't contain, case sensitive
  nbetween: null,
}

export function encodeFilterParams(filters?: CrudFilters) {
  const encodedFilters = filters
    ?.map((filter) => {
      if (filter.operator === 'or') {
        return filter.value
          .map(encodeLogicalFilter)
          .filter(Boolean)
          .join(',or,')
      }
      return encodeLogicalFilter(filter)
    })
    .filter(Boolean)

  if (!encodedFilters?.length) {
    return undefined
  }
  return encodedFilters
}

function encodeLogicalFilter(filter: LogicalFilter): string | undefined {
  const { field, value } = filter
  const operator = operatorsMap[filter.operator]

  if (!operator) {
    throw Error(`The ${filter.operator} operator is not supported by the API`)
  }

  if (['null', 'nnull'].includes(operator)) {
    return `${field},${operator}`
  }

  if (value === undefined || value === '') return undefined

  if (['in', 'nin', 'btw'].includes(operator)) {
    if (!Array.isArray(value)) {
      throw Error(`The value of ${filter.operator} should be an array`)
    }
    const filteredValues = value.filter(
      (val) => val !== undefined && val !== '',
    )
    if (!filteredValues.length) return undefined

    if (filteredValues.length === 1) {
      return buildQueryParam(field, 'eq', filteredValues[0])
    }

    return buildQueryParam(field, operator, filteredValues.join(','))
  }

  if (Array.isArray(value)) {
    return encodeLogicalFilter({
      ...filter,
      operator: operator === 'neq' ? 'nin' : 'in',
    })
  }

  return buildQueryParam(field, operator, value)
}

function buildQueryParam(
  field: string,
  operator: string,
  value: string | number | boolean,
) {
  let parsedValue = value
  if (operator === 'like') {
    parsedValue = String(value)
      .split(' ')
      .filter(Boolean)
      .map(removeDiacritics)
      .map((x) => x.toLowerCase())
      .map((x) => x.replace('%', '\\%'))
      .join('%')
    parsedValue = `%${parsedValue}%`
  }

  return `${field},${operator},${parsedValue}`
}
