import { Button, Form, Input, Select, useSelect } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { verifyPassword } from 'src/libs/verifyPassword'
import type { Domain } from 'src/types/api'
import { EnumOrganizationFrom } from 'src/types/api'

export function SignupForm() {
  const __ = useTranslate()
  const httpClient = useHttpClient()

  const { selectProps } = useSelect<Domain>({
    resource: 'Domain',
    optionLabel: 'name',
  })

  const { open } = useNotification()
  const { mutate } = useMutation<any, AxiosError, Record<string, string>>(
    async (value) => {
      const response = await httpClient.post('/Organization', {
        ...value,
        from: EnumOrganizationFrom.tallia,
      })

      return httpClient.post(
        '/user',
        {
          ...value,
          access_level: 50,
        },
        {
          headers: {
            jwt: response.headers['x-login-jwt'],
          },
        },
      )
    },
    {
      onSuccess() {
        open?.({ type: 'success', message: __('notifications.signupPending') })
      },
      onError(error) {
        open?.({
          type: 'error',
          message: __('notifications.error', {
            statusCode: error.response?.status,
          }),
        })
      },
    },
  )
  return (
    <Form onFinish={mutate} layout="vertical">
      <Form.Item
        label={__('pages.login.name')}
        name="name"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={__('pages.login.username')}
        name="login"
        rules={[
          {
            type: 'email',
            message: __('validation.email'),
          },
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={__('pages.login.password')}
        name="password"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
          {
            async validator(_, value) {
              const [valid, error] = verifyPassword(value)
              if (valid) return Promise.resolve()

              return Promise.reject(new Error(__(error)))
            },
          },
        ]}
        extra={__('pages.login.passwordHelper')}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={__('pages.login.confirmPassword')}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
          (form) => {
            const { getFieldValue } = form
            return {
              validator(_, value) {
                if (value === getFieldValue('password')) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(__('validation.matchingPassword')),
                )
              },
            }
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={__('pages.login.domain')}
        name="domain_id"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Select {...selectProps} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {__('buttons.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}
