import { useForm, Form, Edit, Alert } from '@pankod/refine-antd'
import { useGetIdentity, useTranslate } from '@pankod/refine-core'
import { useParams } from 'react-router'
import type { User } from 'src/types/api'
import { LoadingContent } from 'src/UI'
import { AdminPage } from 'src/UI/AdminPage'

import { FormInputs } from './Form/FormInputs'

export function EditView() {
  const { formProps, saveButtonProps } = useForm<User>({
    redirect: 'list',
  })
  const { id } = useParams()
  const __ = useTranslate()

  const { data, isLoading } = useGetIdentity<User>()

  if (isLoading) {
    return <LoadingContent />
  }
  const isSelf = id === data?.id
  return (
    <AdminPage>
      <Edit
        canDelete={false}
        saveButtonProps={{ ...saveButtonProps, disabled: isSelf }}
        title={`Edition d'un utilisateur`}
      >
        {isSelf && (
          <Alert
            type="warning"
            message={__('users.warnings.isSelf')}
            showIcon
          />
        )}
        <Form {...formProps} layout="vertical">
          <FormInputs isSelf={isSelf} />
        </Form>
      </Edit>
    </AdminPage>
  )
}
