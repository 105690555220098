import React from 'react'
import ReactDOM from 'react-dom'
import { Env } from 'src/adapters/Env'

import { App } from './App'
import { setup } from './setup'
import './i18n'

const env = Env()
setup(env)

ReactDOM.render(
  <React.StrictMode>
    <App env={env} />
  </React.StrictMode>,
  document.getElementById('root'),
)
