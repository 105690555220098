import { useForm, Form, Create } from '@pankod/refine-antd'
import { useOne } from '@pankod/refine-core'
import type { User } from 'src/types/api'
import { AdminPage } from 'src/UI/AdminPage'

import { FormInputs } from './Form/FormInputs'

export function CreateView() {
  const { formProps, saveButtonProps } = useForm<User>({
    redirect: 'list',
  })
  const { data: me } = useOne<User>({ resource: 'me', id: '' })
  const organization_id = me?.data.organization_id

  return (
    <AdminPage>
      <Create
        saveButtonProps={saveButtonProps}
        title={`Création d'un utilisateur`}
      >
        {me && (
          <Form
            {...formProps}
            layout="vertical"
            initialValues={{ organization_id: organization_id }}
          >
            <FormInputs />
          </Form>
        )}
      </Create>
    </AdminPage>
  )
}
