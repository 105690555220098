import { RecoverPasswordForm } from './RecoverPasswordForm'

import './index.less'

export function RecoverUser() {
  return (
    <div className="init-page-container">
      <div className="init-page-content">
        <h1 className="title">Tallia</h1>
        <RecoverPasswordForm />
      </div>
    </div>
  )
}
