import { CreateButton, List, Table, useTable } from '@pankod/refine-antd'
import {
  useCreate,
  useTranslate,
  useNotification,
  useNavigation,
} from '@pankod/refine-core'
import { Link } from 'react-router-dom'
import { TALLIA_DATAPROVIDER_NAME } from 'src/config'
import type { GetDocumentResponse } from 'src/types/talliaApi'

import { CategoryField } from './CategoryField'

export function DocumentsList() {
  const __ = useTranslate()
  const { tableProps } = useTable<GetDocumentResponse>({
    dataProviderName: TALLIA_DATAPROVIDER_NAME,
  })

  return (
    <List
      pageHeaderProps={{
        extra: <CreateDocumentButton />,
      }}
    >
      <Table {...tableProps} rowKey={(document) => document.document_id}>
        <Table.Column<GetDocumentResponse>
          dataIndex="title"
          title={__('documents.fields.title')}
          render={(title, document) => {
            return <Link to={`edit/${document.document_id}`}>{title}</Link>
          }}
        />
        <Table.Column<GetDocumentResponse>
          dataIndex="category"
          title={__('documents.fields.category')}
          render={(_, document) => {
            if (!document.category) {
              return null
            }
            return <CategoryField id={document.category} />
          }}
        />
      </Table>
    </List>
  )
}

function CreateDocumentButton() {
  const __ = useTranslate()
  const { edit } = useNavigation()
  const { mutate, isLoading } = useCreate()

  const { open } = useNotification()

  const onClick = () => {
    mutate(
      {
        resource: 'documents',
        values: {},
        dataProviderName: TALLIA_DATAPROVIDER_NAME,
      },
      {
        onSuccess(result) {
          const documentId = result.data.document_id
          edit('documents', documentId)
        },
        onError(error) {
          open?.({
            type: 'error',
            message: __('notifications.error', {
              statusCode: error.statusCode,
            }),
          })
        },
      },
    )
  }

  return (
    <CreateButton loading={isLoading} onClick={onClick}>
      {__('documents.actions.new')}
    </CreateButton>
  )
}
