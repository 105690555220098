import { Form, Input } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useDebouncedEditDocument } from 'src/libs/useDebouncedEditDocument'

import { CategoryInput } from './CategoryInput'

import type { DocumentFormProps } from '.'

export function MetasForm(props: DocumentFormProps) {
  const { document } = props
  const __ = useTranslate()

  const debouncedUpdateMetas = useDebouncedEditDocument({
    id: document.id,
    property: 'metas',
  })

  return (
    <Form
      layout="vertical"
      initialValues={document.metas}
      onValuesChange={(newValues, values) =>
        debouncedUpdateMetas({ ...values, ...newValues })
      }
    >
      <Form.Item name="title" label={__('documents.fields.title')}>
        <Input />
      </Form.Item>

      <CategoryInput categoryId={document.metas.category} />

      <Form.Item name="description" label={__('documents.fields.description')}>
        <Input.TextArea />
      </Form.Item>
    </Form>
  )
}
