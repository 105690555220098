export type Category = {
  id: number
  domain_id: number
  name: string
  translations?: string
  change_dt: string // date-time
  deleted: boolean
}

export type Document = {
  id: string
  organization_id: string
  level: number
  mime: string
  path: string
  title: string
  description?: string
  domain_id: number
  category_id: number
  keywords: string
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
  is_shareable_internal: boolean
  is_shareable_external: boolean
  weight?: number
  cover?: string
  lang: string
  mark_id?: number
}

export type Domain = {
  id: number
  name: string
  translations?: string
  change_dt: string // date-time
  deleted: boolean
}

export type Favorite = {
  id: number
  user_id: string
  document_id: string
  change_dt: string // date-time
  deleted: boolean
}

export type FullCategory = {
  id: number
  name: string
  translations?: string
  domain_name: string
  domain_translations?: string
  organization_id: number
  deleted: number
  change_dt: string // date-time
}

export type FullDocument = {
  id: string
  mime: string
  path: string
  title: string
  description?: string
  domain_id: number
  category_id: number
  keywords: string
  create_dt: string // date-time
  weight?: number
  is_shareable_internal: boolean
  is_shareable_external: boolean
  cover?: string
  lang: string
  mark_id?: number
  level: number
  langs?: string
  domain_name?: string
  domain_translations?: string
  mark_name?: string
  category_name?: string
  category_translations?: string
  organization_id: number
  organization_name: string
  organization_logo?: string
  author_organization_id: string
  deleted: number
  change_dt: string // date-time
  is_shareable: number
}

export type FullFollower = {
  id: number
  follower_id: number
  following: number
  level: number
  deleted: boolean
  name: string
  logo?: string
  logo_path?: string
  logo_mime?: string
}

export type FullFollowing = {
  id: number
  organization_id: number
  following: number
  level: number
  deleted: boolean
  name: string
  logo?: string
  logo_path?: string
  logo_mime?: string
}

export type FullOrganization = {
  id: number
  name: string
  description?: string
  logo?: string
  valid: boolean
  change_dt: string // date-time
  create_dt: string // date-time
  deleted: boolean
  website?: string
  langs?: string
  marks?: string
  categories?: string
  domain_id: number
  interested_in?: string
  from: EnumFullOrganizationFrom
  tallia_api_key?: string
  full_mark?: string
  logo_path?: string
  logo_mime?: string
}

export type InterrestedIn = {
  id: number
  organization_id: number
  category_id: number
}

export type Lang = {
  code?: string
  name: string
  translations: string
}

export type Level = {
  id: number
  name: string
  translations: string
}

export type Logo = {
  id: string
  path: string
  mime: string
  create_dt?: string // date-time
  change_dt?: string // date-time
  deleted?: boolean
}

export type Mark = {
  id: number
  domain_id: number
  name: string
  category_id?: number
  change_dt: string // date-time
  deleted: boolean
}

export type Organization = {
  id: number
  name: string
  description?: string
  logo?: string
  valid: boolean
  change_dt: string // date-time
  create_dt: string // date-time
  deleted: boolean
  website?: string
  langs?: string
  marks?: string
  categories?: string
  domain_id: number
  interested_in?: string
  from: EnumOrganizationFrom
  tallia_api_key?: string
}

export type Relationship = {
  id: number
  organization_id: number
  following: number
  level: number
  change_dt: string // date-time
  deleted: boolean
}

export type Target = {
  organization_id: number
  id: number
  name: string
  description?: string
  logo?: string
  valid: boolean
  change_dt: string // date-time
  create_dt: string // date-time
  deleted: boolean
  website?: string
  langs?: string
  marks?: string
  categories?: string
  domain_id: number
  interested_in?: string
  from: EnumTargetFrom
  tallia_api_key?: string
  logo_path?: string
  logo_mime?: string
}

export type User = {
  id: string
  login: string
  password?: string
  organization_id: number
  create_dt: string // date-time
  change_dt: string // date-time
  deleted: boolean
  access_level: number
  domain_id?: number
  nasqah_id?: string
}

export enum EnumFullOrganizationFrom {
  'docwall' = 'docwall',
  'tallia' = 'tallia',
}

export enum EnumOrganizationFrom {
  'docwall' = 'docwall',
  'tallia' = 'tallia',
}

export enum EnumTargetFrom {
  'docwall' = 'docwall',
  'tallia' = 'tallia',
}
