import { notificationProvider } from '@pankod/refine-antd'
import { Refine } from '@pankod/refine-core'
import routerProvider from '@pankod/refine-react-router-v6'
import { DataProvider } from '@tallia/nx-api'
import { memo, Suspense, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { antiCorruptionLayerProxy } from './adapters/antiCorruptionLayerProxy'
import {
  AuthProvider,
  getDocwallJWT,
  getTalliaJWT,
} from './adapters/AuthProvider'

import type { IEnv } from './adapters/Env'
import { useEnv, EnvContext } from './adapters/Env'
import { HTTPClient, HttpClientContext } from './adapters/HTTPClient'
import { useI18nProvider } from './adapters/i18nProvider'
import { TalliaHttpClientContext } from './adapters/TalliaHttpClient'
import { TALLIA_DATAPROVIDER_NAME } from './config'
import { local } from './libs/storage'
import { RecoverUser } from './Pages/InitUser'
import { Me } from './Pages/Me'
import { resources } from './Resources'
import { Menu, Title, LoadingPage, Layout } from './UI'
import { Footer } from './UI/Footer'
import { LoginPage } from './UI/Login/LoginPage'
import './UI/index.less'

type Props = {
  env: IEnv
}

export function App(props: Props) {
  const { env } = props

  return (
    <Suspense fallback={<LoadingPage />}>
      <EnvContext.Provider value={env}>
        <AppContent />
      </EnvContext.Provider>
    </Suspense>
  )
}

const reactQueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000, // 10 seconds
    },
  },
}

const customRouterProvider = {
  ...routerProvider,
  Link,
  routes: [
    { path: '/me', element: <Me />, layout: true },
    { path: '/reset-password', element: <RecoverUser /> },
  ],
}

const AppContent = memo(function () {
  const env = useEnv()
  const i18nProvider = useI18nProvider()

  const docwallHttpClient = useMemo(
    () =>
      HTTPClient({
        getAccessToken() {
          return Promise.resolve(getDocwallJWT(local))
        },
        baseURL: env.DOCWALL_URL,
      }),
    [env.DOCWALL_URL],
  )

  const talliaHttpClient = useMemo(
    () =>
      HTTPClient({
        getAccessToken() {
          return Promise.resolve(getTalliaJWT(local))
        },
        baseURL: env.TALLIA_URL,
      }),
    [env.TALLIA_URL],
  )

  const dataProvider = useMemo(
    () =>
      antiCorruptionLayerProxy(
        DataProvider({
          httpClient: docwallHttpClient,
          apiUrl: env.DOCWALL_URL,
        }),
      ),
    [env.DOCWALL_URL, docwallHttpClient],
  )

  const talliaDataProvider = useMemo(
    () =>
      antiCorruptionLayerProxy(
        DataProvider({
          httpClient: talliaHttpClient,
          apiUrl: env.TALLIA_URL,
        }),
      ),
    [env.TALLIA_URL, talliaHttpClient],
  )

  const authProvider = useMemo(
    () =>
      AuthProvider({
        nxHttpClient: docwallHttpClient,
        local: local,
        translate: i18nProvider.translate,
      }),
    [docwallHttpClient, i18nProvider.translate],
  )

  return (
    <TalliaHttpClientContext.Provider value={talliaHttpClient}>
      <HttpClientContext.Provider value={docwallHttpClient}>
        <Refine
          routerProvider={customRouterProvider}
          dataProvider={{
            default: dataProvider,
            [TALLIA_DATAPROVIDER_NAME]: talliaDataProvider,
          }}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          notificationProvider={notificationProvider}
          reactQueryClientConfig={reactQueryClientConfig}
          resources={resources}
          Sider={Menu}
          Title={Title}
          Footer={Footer}
          Layout={Layout}
          LoginPage={LoginPage}
        />
      </HttpClientContext.Provider>
    </TalliaHttpClientContext.Provider>
  )
})
