import {
  AntdLayout,
  useMenu,
  Menu as RefineMenu,
  Icons,
  Spin,
} from '@pankod/refine-antd'
import { useLogout, usePermissions, useTranslate } from '@pankod/refine-core'
import classnames from 'classnames'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PermissionEnum } from 'src/adapters/AuthProvider'

import './Menu.less'
import { Title } from './Title'

type MenuItem = { name: string; Icon: any; title?: string }

function menuItems(isAdmin: boolean): MenuItem[] {
  return [
    { name: 'documents', Icon: Icons.FolderOpenOutlined },
    isAdmin && { name: 'user', Icon: Icons.UserOutlined, title: 'users.name' },
    { name: 'me', title: 'menu.me', Icon: Icons.SettingOutlined },
  ].filter((item: any): item is MenuItem => Boolean(item))
}

export function Menu() {
  const translate = useTranslate()
  const { selectedKey } = useMenu()
  const { mutate: logout } = useLogout()
  const { data, isLoading } = usePermissions<PermissionEnum>()

  const isAdmin = data === PermissionEnum.ADMIN

  return (
    <MenuLayout>
      <RefineMenu
        className="NxMenu"
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={(event) => {
          const { key } = event
          if (key === 'logout') {
            logout()
          }
        }}
      >
        {isLoading ? (
          <Spin />
        ) : (
          menuItems(isAdmin).map((menuItem) => {
            const { name, Icon, title } = menuItem
            const path = `/${name}`
            const isActive = selectedKey === path
            return (
              <RefineMenu.Item
                key={path}
                icon={<Icon />}
                className={classnames('NxMenuItem', isActive ? '-active' : '')}
              >
                <Link to={path}>
                  {isActive && '> '}
                  {translate(title ?? `${name}.name`)}
                </Link>
              </RefineMenu.Item>
            )
          })
        )}

        <RefineMenu.Item
          key="logout"
          icon={<Icons.LogoutOutlined />}
          className={classnames('NxMenuItem', '-logoutButton')}
        >
          {translate('buttons.logout')}
        </RefineMenu.Item>
      </RefineMenu>
    </MenuLayout>
  )
}

export function MenuLayout(props: PropsWithChildren<{}>) {
  const { children } = props
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      className="NxLayoutSider"
    >
      <Title />

      {children}
    </AntdLayout.Sider>
  )
}
