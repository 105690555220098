import { CreateView } from './Create'
import { EditView } from './Edit'
import { ListView } from './List'

export const users = {
  name: 'user',
  list: ListView,
  create: CreateView,
  edit: EditView,
}
