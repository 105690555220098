import { Button, Checkbox } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import debounce from 'debounce'
import { useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useEnv } from 'src/adapters/Env'
import { useTalliaHttpClient } from 'src/adapters/TalliaHttpClient'

import { DocumentUpload } from './DocumentUpload'

import type { DocumentFormProps } from '.'

const languages = [
  'fr',
  'en',
  'de',
  'es',
  'it',
  'cs',
  'hu',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'tr',
]

export function LangsForm(props: DocumentFormProps) {
  const { document } = props
  const __ = useTranslate()

  const talliaHttpClient = useTalliaHttpClient()
  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    (newValue: string[]) => {
      return talliaHttpClient.post(`document/${document.id}/langs`, {
        langs: newValue,
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['document', document.id])
      },
    },
  )

  const debouncedUpdateLangs = useMemo(
    () =>
      debounce((newValue: Set<string>) => {
        mutate(Array.from(newValue))
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const [selectedLanguages, setSelectedLanguages] = useState(
    new Set(Object.keys(document.langs)),
  )

  const onClickLanguage = (lang: string) => {
    return setSelectedLanguages((prev) => {
      const value = new Set(prev)
      if (value.has(lang)) {
        value.delete(lang)
      } else {
        value.add(lang)
      }
      debouncedUpdateLangs(value)
      return value
    })
  }

  return (
    <fieldset className="language-form">
      <legend>{__('documents.fields.langs')}</legend>
      {languages.map((lang) => {
        const isChecked = selectedLanguages.has(lang)
        const isMain = Boolean(document.langs?.[lang]?.main)
        const isAuto = document.langs?.[lang]?.auto
        return (
          <div className="language-item" key={lang}>
            <Checkbox
              checked={isChecked}
              onClick={() => onClickLanguage(lang)}
              disabled={isMain}
              className="lang"
            >
              {__(`languages.${lang}`)}
            </Checkbox>
            {isChecked && (
              <TranslateActionsComponent
                lang={lang}
                documentId={document.id}
                isMain={isMain}
                isAuto={isAuto}
              />
            )}
          </div>
        )
      })}
    </fieldset>
  )
}

type TranslateActionsComponentProps = {
  lang: string
  documentId: string
  isMain: boolean
  isAuto?: boolean
}

function TranslateActionsComponent(props: TranslateActionsComponentProps) {
  const { lang, documentId, isMain, isAuto } = props

  const __ = useTranslate()
  const env = useEnv()

  const talliaHttpClient = useTalliaHttpClient()
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useMutation(() => {
    return talliaHttpClient.get(`d/${documentId}/${lang}?renew=1&nosend=1`, {
      timeout: 30000,
    })
  })
  function generateTranslation() {
    mutate(undefined, {
      onSuccess() {
        queryClient.invalidateQueries(['document', documentId])
      },
    })
  }

  const btnLabel = 'buttons.upload_official'
  if (isMain) {
    return (
      <>
        <span className="docx">{__('languages.main')}</span>
        <a
          className="pdf"
          target="_blank"
          href={`${env.TALLIA_URL}/d/${documentId}/${lang}?ext=pdf`}
          rel="noreferrer"
        >
          {__('buttons.download_pdf')}
        </a>
      </>
    )
  }
  if (isAuto) {
    return (
      <>
        <a
          target="_blank"
          href={`${env.TALLIA_URL}/d/${documentId}/${lang}?ext=docx`}
          rel="noreferrer"
          className="docx"
        >
          {__('buttons.download_docx')}
        </a>
        <a
          target="_blank"
          href={`${env.TALLIA_URL}/d/${documentId}/${lang}?ext=pdf`}
          rel="noreferrer"
          className="pdf"
        >
          {__('buttons.download_pdf')}
        </a>
        <DocumentUpload
          className="upload"
          documentId={documentId}
          lang={lang}
          label={btnLabel}
        />
      </>
    )
  }

  if (isAuto === false) {
    return (
      <>
        <a
          target="_blank"
          href={`${env.TALLIA_URL}/d/${documentId}/${lang}?ext=pdf`}
          rel="noreferrer"
          className="pdf -replace"
        >
          {__('buttons.download_pdf')}
        </a>
        <DocumentUpload
          className="upload -replace"
          documentId={documentId}
          lang={lang}
          label={btnLabel}
        />
      </>
    )
  }

  return (
    <>
      <Button
        loading={isLoading}
        onClick={generateTranslation}
        className="pdf -replace"
      >
        {__('buttons.generate_translation')}
      </Button>
      <DocumentUpload
        className="upload -replace"
        documentId={documentId}
        lang={lang}
        label={btnLabel}
      />
    </>
  )
}
