import type { UploadProps } from '@pankod/refine-antd'
import { Progress, Button, Icons, Upload } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTalliaHttpClient } from 'src/adapters/TalliaHttpClient'

type Props = {
  documentId: string
  lang?: string
  dropdown?: boolean
  className?: string
  label?: string
}
export function DocumentUpload(props: Props) {
  const { documentId, lang, dropdown, className, label } = props
  const talliaHttpClient = useTalliaHttpClient()
  const queryClient = useQueryClient()
  const __ = useTranslate()
  const { open } = useNotification()

  const [isUploading, setIsUploading] = useState(false)

  const [progressPercent, setProgressPercent] = useState<undefined | number>(
    undefined,
  )

  const uploadProps: UploadProps = {
    accept: 'application/pdf',
    maxCount: 1,
    showUploadList: false,
    disabled: isUploading,
    async customRequest(options) {
      const { file, onSuccess, onError } = options

      const formData = new FormData()
      formData.append('file', file)
      if (lang) formData.append('lang', lang)

      try {
        setIsUploading(true)
        const request = await talliaHttpClient.post(
          `document/${documentId}`,
          formData,
          {
            onUploadProgress(progressEvent) {
              setProgressPercent(
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
              )
            },
          },
        )
        open?.({ type: 'success', message: __('notifications.uploadSuccess') })
        onSuccess?.(request.data)
        queryClient.invalidateQueries(['document', documentId])
      } catch (error: any) {
        onError?.(error)
        open?.({
          type: 'error',
          message: __('notifications.uploadError', {
            statusCode: error.statusCode,
          }),
        })
      } finally {
        setIsUploading(false)
      }
    },
  }

  if (dropdown) {
    return (
      <div className={className}>
        <Upload.Dragger {...uploadProps}>
          <p>{__('dropzone.label')}</p>
        </Upload.Dragger>
        <Progress
          percent={progressPercent}
          status={isUploading ? 'active' : 'normal'}
        />
      </div>
    )
  }
  const translatedLabel = label && __(label)
  return (
    <Upload {...uploadProps} className={className}>
      {isUploading ? (
        <Progress
          percent={progressPercent}
          status="active"
          type="circle"
          strokeWidth={10}
          width={24}
          showInfo={false}
        />
      ) : (
        <Button icon={<Icons.UploadOutlined />} title={translatedLabel}>
          <span className="label">{translatedLabel}</span>
        </Button>
      )}
    </Upload>
  )
}
