import {
  List,
  Table,
  useTable,
  Space,
  CreateButton,
  Tag,
} from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { EditButton } from 'src/components/ResourceButtons'
import type { User } from 'src/types/api'
import { AdminPage } from 'src/UI/AdminPage'

export function ListView() {
  const __ = useTranslate()

  const { tableProps } = useTable<User>({
    resource: 'user',
    initialSorter: [
      {
        field: 'login',
        order: 'desc',
      },
    ],
  })

  return (
    <AdminPage>
      <List
        title={__('users.name')}
        pageHeaderProps={{
          extra: <CreateButton>{__('users.actions.new')}</CreateButton>,
        }}
      >
        <Table<User> {...tableProps} rowKey="id">
          <Table.Column dataIndex={'login'} title={__('users.fields.email')} />
          <Table.Column
            dataIndex={['access_level']}
            title={__('users.fields.role')}
            render={(accessLevel) => {
              return __(getRole(accessLevel))
            }}
          />
          <Table.Column
            dataIndex={['deleted']}
            title={__('users.fields.status')}
            render={(deleted) => getStatus(deleted)}
          />
          <Table.Column<User>
            dataIndex="actions"
            render={(_, record) => {
              return (
                <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
                  <EditButton recordItemId={record?.id} />
                </Space>
              )
            }}
          />
        </Table>
      </List>
    </AdminPage>
  )
}

function getRole(accessLevel: number) {
  const roles =
    accessLevel <= 20 ? 'roles.values.user' : 'roles.values.administrator'
  return roles
}
function getStatus(deleted: boolean) {
  const status = deleted ? 'inactif' : 'actif'
  const style = deleted ? 'red' : 'blue'
  return <Tag color={style}>{status}</Tag>
}
