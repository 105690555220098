import { Show, Typography } from '@pankod/refine-antd'
import { Authenticated, useOne, useTranslate } from '@pankod/refine-core'
import { useNavigate } from 'react-router'
import type { User } from 'src/types/api'

import { ChangePasswordForm } from './ChangePasswordForm'

import './index.less'

const { Title, Text } = Typography

type LoginUser = {
  organization_name: string
} & User

export function Me() {
  const { data } = useOne<LoginUser>({ resource: 'me', id: '' })
  const __ = useTranslate()
  const navigate = useNavigate()

  const user = data?.data

  return (
    <Authenticated>
      <Show
        title={__('menu.me')}
        pageHeaderProps={{
          onBack() {
            navigate(-1)
          },
          extra: undefined,
        }}
      >
        <div className="page-me-content">
          {user && (
            <>
              <Title level={5}>{__('users.fields.login')}</Title>
              <Text>{user.login}</Text>
              <Title level={5}>{__('users.fields.organization_name')}</Title>
              <Text>{user.organization_name}</Text>
              <ChangePasswordForm user={user} />
            </>
          )}
        </div>
      </Show>
    </Authenticated>
  )
}
