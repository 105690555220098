import type { AxiosInstance } from 'axios'
import { createContext, useContext } from 'react'

export const TalliaHttpClientContext = createContext<AxiosInstance | undefined>(
  undefined,
)

export function useTalliaHttpClient(): AxiosInstance {
  const httpClient = useContext(TalliaHttpClientContext)

  if (!httpClient) throw new Error('Please provide an HttpClient')

  return httpClient
}
