import { Grid, Skeleton, Menu as RefineMenu } from '@pankod/refine-antd'
import { Layout } from 'antd'
import { BrowserRouter } from 'react-router-dom'

import { MenuLayout } from './Menu'

export function LoadingPage() {
  /* browser router is required for Title's <Link /> tag not to throw */
  return (
    <BrowserRouter>
      <Layout style={{ minHeight: '100vh', flexDirection: 'row' }}>
        <MenuLayout>
          <RefineMenu>
            <RefineMenu.Item key="skeleton1">
              <Skeleton active />
            </RefineMenu.Item>
            <RefineMenu.Item key="skeleton2">
              <Skeleton active />
            </RefineMenu.Item>
            <RefineMenu.Item key="skeleton3">
              <Skeleton active />
            </RefineMenu.Item>
          </RefineMenu>
        </MenuLayout>
        <Layout>
          <LoadingContent />
        </Layout>
      </Layout>
    </BrowserRouter>
  )
}

export function LoadingContent() {
  const breakpoint = Grid.useBreakpoint()
  return (
    <Layout.Content>
      <div
        style={{
          padding: breakpoint.sm ? 24 : 12,
          minHeight: 360,
        }}
      >
        <Skeleton active />
      </div>
    </Layout.Content>
  )
}
