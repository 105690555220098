import { Edit, Image } from '@pankod/refine-antd'
import type { ResourceRouterParams } from '@pankod/refine-core'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { useTalliaHttpClient } from 'src/adapters/TalliaHttpClient'
import { TALLIA_DATAPROVIDER_NAME } from 'src/config'
import type { Document } from 'src/types/talliaApi'

import { DocumentForm } from './Form'

import './index.less'

export function DocumentEdit() {
  const { id } = useParams<ResourceRouterParams>()

  if (!id) throw new Error('Undefined id')

  const talliaHttpClient = useTalliaHttpClient()

  const { data } = useQuery(
    ['document', id],
    () => {
      return talliaHttpClient.get<Document>(`document/${id}`)
    },
    { suspense: true },
  )
  if (data) {
    return (
      <Edit dataProviderName={TALLIA_DATAPROVIDER_NAME} actionButtons={<></>}>
        <div className="document-edit">
          <Image src={data.data.infos.qrcode} />
          <a href={data.data.infos.url} target="_blank" rel="noreferrer">
            {data.data.infos.url}
          </a>

          <DocumentForm document={{ ...data.data, id }} />
        </div>
      </Edit>
    )
  }

  return null
}
