import { Card } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'

import { Form } from './Form'

import './index.less'

export function LoginPage() {
  const __ = useTranslate()
  return (
    <div className="login-page-container">
      <div className="login-page-content">
        <div>
          <h1 className="login-title">Tallia</h1>
          <p style={{ textAlign: 'justify' }}>
            {__('pages.description.content')}
          </p>
          <span>{__('pages.description.functionality.text')} :</span>
          <ul>
            <li>{__('pages.description.functionality.documents.text')} :</li>
            <ul>
              <li>{__('pages.description.functionality.documents.qrcode')}</li>
              <li>{__('pages.description.functionality.documents.upload')}</li>
              <li>
                {__('pages.description.functionality.documents.translation')}
              </li>
            </ul>
            <li>{__('pages.description.functionality.users')}</li>
            <li>{__('pages.description.functionality.account')}</li>
          </ul>
        </div>
        <Card className="login-form">
          <Form />
        </Card>
      </div>
    </div>
  )
}
