import type { AxiosResponse } from 'axios'
import debounce from 'debounce'
import { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useTalliaHttpClient } from 'src/adapters/TalliaHttpClient'
import type { Document } from 'src/types/talliaApi'

type Option = {
  id: string
  property: string
}

export function useDebouncedEditDocument(option: Option) {
  const { id, property } = option
  const talliaHttpClient = useTalliaHttpClient()
  const queryClient = useQueryClient()

  const { mutate } = useMutation(
    (newValue: Record<string, any>) => {
      return talliaHttpClient.post(`document/${id}/${property}`, newValue)
    },
    {
      onSuccess(_res, newValue) {
        queryClient.setQueryData<AxiosResponse<Document> | undefined>(
          ['document', id],
          (prevResponse) => {
            if (!prevResponse) return
            return {
              ...prevResponse,
              data: {
                ...prevResponse.data,
                [property]: newValue,
              },
            }
          },
        )
      },
    },
  )

  return useMemo(
    () =>
      debounce((newValue: Record<string, any>) => {
        mutate(newValue)
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}
