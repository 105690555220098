import { usePermissions } from '@pankod/refine-core'
import type { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router'
import { PermissionEnum } from 'src/adapters/AuthProvider'

import { ErrorPage } from './ErrorPage'
import { LoadingContent } from './LoadingPage'

export function AdminPage(props: PropsWithChildren<{}>) {
  const { children } = props
  const { data, isLoading } = usePermissions<PermissionEnum>()
  const navigate = useNavigate()

  if (isLoading) return <LoadingContent />

  if (data !== PermissionEnum.ADMIN) {
    return (
      <ErrorPage
        resetErrorBoundary={() => navigate(-1)}
        error={{ message: 'pages.error.403', name: 'Unauthorized', code: 403 }}
      />
    )
  }
  return <>{children}</>
}
