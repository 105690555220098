import type {
  DataProvider as IDataProvider,
  Pagination,
  CrudSorting,
} from '@pankod/refine-core'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'

import { encodeFilterParams } from './buildFilters'

import type {
  CreateManyParams,
  CreateParams,
  CustomParams,
  DeleteManyParams,
  DeleteOneParams,
  GetListParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams,
} from './types'

export * from './types'

type DataProviderConfig = {
  httpClient: AxiosInstance
  apiUrl: string
}

function encodeLimitParams(pagination?: Pagination) {
  if (pagination?.pageSize) {
    const { pageSize, current } = pagination
    const start = ((pagination.current ?? 1) - 1) * pageSize
    const end = pageSize * (current ?? 1)
    return `${start},${end}`
  }
  return undefined
}

function encodeOrderParams(sort?: CrudSorting) {
  return sort
    ?.map((value) => {
      if (value.order === 'desc') {
        return `${value.field} desc`
      }
      return value.field
    })
    .filter(Boolean)
    .join(',')
}

export function DataProvider(config: DataProviderConfig): IDataProvider {
  const { httpClient, apiUrl } = config

  function request<T>(requestConfig: AxiosRequestConfig) {
    return httpClient.request<T>({
      baseURL: apiUrl,
      ...requestConfig,
    })
  }

  const provider: IDataProvider = {
    async create<T>(options: CreateParams) {
      return request<T>({
        method: 'POST',
        url: `${options.resource}`,
        data: options.variables,
      })
    },

    async createMany<T>(options: CreateManyParams) {
      const { variables } = options
      const responses = await Promise.all(
        variables.map((values) =>
          provider.create<T>({ ...options, variables: values as any }),
        ),
      )

      return { data: responses.map((x) => x.data) }
    },

    async getList<T>(options: GetListParams) {
      const { resource, pagination, sort, filters } = options

      const result = await httpClient.get<T>(`/${resource}`, {
        params: {
          limit: encodeLimitParams(pagination),
          order: encodeOrderParams(sort),
          filter: encodeFilterParams(filters),
          calc_rows: true,
        },
        baseURL: apiUrl,
      })
      return {
        ...result,
        total: parseInt(result.headers['nx-total-rows']),
      }
    },

    async getOne<T>(options: GetOneParams) {
      const { resource, id } = options
      return httpClient.get<T>(`/${resource}/${id}`, { baseURL: apiUrl })
    },

    async getMany(options) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) => provider.getOne({ ...options, id })),
      )
      const data = responses.map((x) => x.data as any).filter(Boolean)
      return { data, total: data.length }
    },

    async update<T>(options: UpdateParams) {
      const { variables, resource, id } = options
      return httpClient.put<T>(`/${resource}/${id}`, variables, {
        baseURL: apiUrl,
      })
    },

    async updateMany<T>(options: UpdateManyParams) {
      const { ids } = options

      const responses = await Promise.all(
        ids.map((id) =>
          provider.update<T>({
            ...options,
            id,
            variables: options.variables as any,
          }),
        ),
      )
      return { data: responses.map((res) => res.data) }
    },

    async deleteOne(options: DeleteOneParams) {
      const { resource, id } = options
      return httpClient.delete(`/${resource}/${id}`, { baseURL: apiUrl })
    },

    async deleteMany<T>(options: DeleteManyParams) {
      const { ids, variables } = options

      const responses = await Promise.all(
        ids.map((id) =>
          provider.deleteOne<T>({
            ...options,
            variables: variables as any,
            id,
          }),
        ),
      )

      return {
        data: responses
          .map((deleteResult) => deleteResult?.data)
          .filter(Boolean),
      }
    },

    async custom(options: CustomParams) {
      return httpClient.request({ baseURL: apiUrl, ...options })
    },

    getApiUrl() {
      return apiUrl
    },
  }

  return provider
}
