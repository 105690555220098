import packageJson from '../package.json'

import spec from './types/api/spec.json'

export const config = {
  APP_VERSION: packageJson.version,
  APP_NAME: packageJson.name,
  APP_DISPLAY_NAME: packageJson.displayName,
  APP_RELEASE: `${packageJson.name}@${packageJson.version}` as const,
  API_VERSION: spec.info.version,

  features: {} as const,
} as const

export const TALLIA_DATAPROVIDER_NAME = 'tallia' as const
