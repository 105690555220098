import { Form, Typography, Input, Button } from '@pankod/refine-antd'
import { useTranslate, useNotification } from '@pankod/refine-core'
import { useMutation } from 'react-query'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { verifyPassword } from 'src/libs/verifyPassword'
import type { User } from 'src/types/api'

const { Title } = Typography

type Props = {
  user: User
}

type ChangePasswordFormData = {
  oldPassword: string
  newPassword: string
}

export function ChangePasswordForm(props: Props) {
  const { user } = props

  const __ = useTranslate()
  const { open } = useNotification()

  const [form] = Form.useForm()

  const httpClient = useHttpClient()
  const { mutate: changePassword } = useMutation(
    async (value: ChangePasswordFormData) => {
      const formData = new FormData()
      formData.append('login', user.login)
      formData.append('password', value.oldPassword)
      try {
        await httpClient.post('me', formData)
      } catch (error) {
        open?.({ type: 'error', message: __('errors.invalidPassword') })
        throw error
      }

      return httpClient.put('me', { password: value.newPassword })
    },
    {
      onSuccess() {
        open?.({
          type: 'success',
          message: __('notifications.changePasswordSuccess'),
        })
        form.resetFields()
      },
    },
  )

  return (
    <Form
      form={form}
      onFinish={changePassword}
      layout="vertical"
      className="password-form"
    >
      <Title level={4}>{__('pages.me.changePassword')}</Title>
      <Form.Item
        label={__('pages.me.oldPassword')}
        name="oldPassword"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={__('pages.me.newPassword')}
        name="newPassword"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
          {
            async validator(_, value) {
              const [valid, error] = verifyPassword(value)
              if (valid) return Promise.resolve()

              return Promise.reject(new Error(__(error)))
            },
          },
        ]}
        extra={__('pages.login.passwordHelper')}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label={__('pages.login.confirmPassword')}
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: __('validation.required'),
          },
          (formInstance) => {
            const { getFieldValue } = formInstance
            return {
              validator(_, value) {
                if (value === getFieldValue('newPassword')) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error(__('validation.matchingPassword')),
                )
              },
            }
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {__('buttons.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}
